var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "LBL0000271" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          label: "LBL0000272",
                          name: "closeEntryResaon",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.closeEntryResaon,
                          callback: function ($$v) {
                            _vm.$set(_vm.supWork, "closeEntryResaon", $$v)
                          },
                          expression: "supWork.closeEntryResaon",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          label: "LBL0000273",
                          name: "closeExpectedRisk",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.closeExpectedRisk,
                          callback: function ($$v) {
                            _vm.$set(_vm.supWork, "closeExpectedRisk", $$v)
                          },
                          expression: "supWork.closeExpectedRisk",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                    [
                      _c("c-tag", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          itemText: "closeEntryUserName",
                          itemValue: "check",
                          icon: "face",
                          label: "LBL0000274",
                          name: "entrants",
                        },
                        on: {
                          removeTag: _vm.removeEntrant,
                          addTag: _vm.addEentrant,
                        },
                        model: {
                          value: _vm.supWork.entrants,
                          callback: function ($$v) {
                            _vm.$set(_vm.supWork, "entrants", $$v)
                          },
                          expression: "supWork.entrants",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c("c-table", {
              attrs: {
                title: "LBL0000232",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.supWork.checkResults,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable && _vm.isWriting,
                contentsField: _vm.contentsField,
              },
              on: { "table-data-change": _vm.tableDataChange },
            }),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }